<template>
  <section class="summary tw-bg-white tw-mb-1 tw-px-4 tw-py-2 tw-relative tw-text-grey-800 tw-z-10 tw-arrow-after-summary-top-left md:tw-arrow-after-summary-left tw-mt-2 md:tw-mt-0 tw--mx-1 sm:tw-mx-0">
    <!-- Sticky Element only tw-visible on <= small viewports, when summary is not already in view -->
    <div v-if="showSummaryHint" class="tw-border-t tw-border-grey-400 tw-fixed tw-bottom-0 tw-left-0 tw-w-full tw-px-2 tw-py-1 tw-bg-white tw-flex tw-space-between tw-z-10">
      <template v-if="selectedLevels.length > 0">
        <div class="tw-flex tw-flex-col">
          <div class="tw-flex">
            <strong>{{ sumPrice }}
              <abbr title="Euro" role="presentation">€</abbr> *</strong>
            <strong class="tw-ml-1">
              {{
                data.lotDuration === LotDuration.Unlimited
                  ? data.sidebarContent.mobileSummaryRecurringLabel
                  : data.sidebarContent.mobileSummaryOneTimeLabel
              }}
            </strong>
          </div>
          <div class="tw-flex">
            <span v-if="monthlyDrawings > 0">Für {{ monthlyDrawings }} Ziehungen</span>
          </div>
        </div>
        <div class="tw-ml-auto">
          <btn button-tag="a" href="#summary" @click="
              trackClick(
                'pk_lotterie_konfi_layer_ctabutton_sticky_zur-bestellung'
              )
            ">{{ data.sidebarContent.jumpToSummaryLabel }}
          </btn>
        </div>
      </template>
      <template v-else>
        <strong class="tw-my-1">{{
          data.sidebarContent.noCategorySelectedLabel
        }}</strong>
      </template>
    </div>
    <img :src="`${data.sidebarContent.lotImage}`" class="lot-image tw-hidden md:tw-block" :class="{ 'tw-opacity-25': loading }" alt="" />
    <div class="tw-mt-2" id="summary">
      <h2 v-if="
          data.sidebarContent.headlinePrefix ||
          data.sidebarContent.headlineLotName
        " class="headline--single-line headline--condensed tw-text-l tw-mb-2">
        <span class="text-red">{{ data.sidebarContent.headlinePrefix }}</span>&nbsp;
        <span>{{ data.sidebarContent.headlineLotName }}</span>
      </h2>
      <ul class="tw-mb-2" data-qa="Checkout_Configurator_Value_WinningCategories">
        <li v-for="(drawing, index) in drawingWinningCategory" :key="index" :class="
            index === 0
              ? ''
              : 'tw-border-t-1 tw-border-solid tw-border-grey-200'
          " :data-qa="
            drawing.name == 'Zusatzspiel'
              ? 'Checkout_Configurator_Value_FeatureGame'
              : null
          ">
          <div class="summary-list__check tw-py-1" :class="{ 'is-selected': hasSelectedLevel(drawing) }">
            <a :href="'#' + $formatAnchor(drawing.name)" class="tw-w-full tw-flex tw-justify-between" :class="{ 'option-available': !hasSelectedLevel(drawing) }">
              <div class="tw-items-center tw-flex-wrap">
                <span :class="{
                    'font-bold': hasSelectedLevel(drawing),
                    'tw-text-grey-500': !hasSelectedLevel(drawing),
                  }">
                  <template v-if="jahreslosData">
                    {{ data.sidebarContent.jahreslosCategoryText }}
                  </template>
                  <template v-else>
                    <span>{{
                      hasSelectedLevel(drawing) ? drawing.drawingsPerMonth : 0
                    }}</span>
                    <span>&nbsp;×&nbsp;</span>
                    <span>{{ drawing.name }}</span>
                    <span v-if="hasSelectedLevel(drawing)">&nbsp;pro Monat</span>
                  </template>
                </span>
                <span v-if="hasSelectedLevel(drawing)" class="md:tw-hidden tw-flex tw-w-full">
                  Chance auf: {{ "\xa0"
                  }}<span>{{ getSelectedLevelWinningAmount(drawing) }}</span>{{ "\xa0" }}<abbr title="Euro">€</abbr>
                </span>
              </div>
              <div class="tw-flex tw-items-center">
                <span class="tw-text-grey-500 tw-mr-2 tw-underline sm:tw-hidden tw-whitespace-no-wrap" v-if="!hasSelectedLevel(drawing)">
                  Option wählen
                </span>
                <span class="check-circle tw-flex-grow-0 tw-rounded-full tw-flex-shrink-0 tw-relative tw-text-white" :class="{
                    'is-checked tw-icon tw-icon-haken-bold':
                      hasSelectedLevel(drawing),
                  }"></span>
              </div>
            </a>
          </div>
        </li>
      </ul>

      <ul class="configurator__summary__infoblock tw-text-m tw-mt-1 tw-mb-2" aria-atomic="true" data-qa="Configurator_SummaryBox_ContentContainer_ParticipationSummary">
        <li class="summary-list__item" data-qa="Configurator_SummaryBox_Value_PlayingStartDate">
          <span class="font-bold">{{ data.sidebarContent.playingDateLabel }}
          </span>
          <span data-qa="Checkout_Configurator_Value_PlayingDate">
            <template v-if="jahreslosData && !data.lotType">
              <a href="#LotType">{{
                data.sidebarContent.playingDatePleaseSelectValue
              }}</a>
            </template>
            <template v-else-if="playingDate === PlayingDateUnspecified">
              Wählt der Beschenkte
            </template>
            <template v-else>
              {{ $formatDate(playingDate, "de-DE") }}
            </template>
          </span>
        </li>

        <li v-if="!jahreslosData" class="summary-list__item tw-relative" data-qa="Configurator_SummaryBox_Value_Cancellation">
          <transition enter-class="tw-opacity-0" enter-active-class="tw-transition-all tw-duration-300" leave-active-class="tw-transition-all tw-duration-300" leave-to-class="tw-opacity-0">
            <div class="tw-absolute tw-z-10 tool-tip" v-show="showCancellationPolicyTip" v-if="
                showCancellationAndRuntimeTips &&
                data.sidebarContent.cancellationPolicyHint
              ">
              <tipp direction="bottom-left" :info="true" :bold="false">
                <template #default>
                  <span v-html="data.sidebarContent.cancellationPolicyHint"></span>
                </template>
              </tipp>
            </div>
          </transition>
          <span class="font-bold">{{
            data.sidebarContent.cancellationPolicyLabel
          }}</span><span>
            {{ data.sidebarContent.cancellationPolicyMonthlyValue }}
          </span>
          <button class="tw-px-1/2 tw-py-1 md:tw-p-0 md:tw-ml-1/2 info-button tw-absolute tw-top-1/2" v-if="
              showCancellationAndRuntimeTips &&
              data.sidebarContent.cancellationPolicyHint
            " @click="
              showCancellationPolicyTip ||
                trackClick(
                  `pk_lotterie_konfi_dein-los_icon_bedingungen_kuendbar-monatlich`
                );
              showRuntimeTip = false;
              showCancellationPolicyTip = !showCancellationPolicyTip;
            ">
            <span class="tw-icon tw-icon-information-bold tw-rounded-full tw-border tw-border-solid tw-border-grey-800" :class="{
                'tw-bg-grey-800 tw-text-white': showCancellationPolicyTip,
              }"></span>
          </button>
        </li>

        <li v-if="jahreslosData && this.data.lotFormat === 'HighQualityPrinted'" class="summary-list__item" data-qa="Configurator_SummaryBox_Value_DurationLimitation">
          <span class="font-bold">{{ data.sidebarContent.shippingDateLabel }} </span><span>{{ $formatDate(this.$props.shippingDate, "de-DE") }}</span>
        </li>

        <li class="summary-list__item tw-relative" data-qa="Configurator_SummaryBox_Value_DurationLimitation">
          <transition enter-class="tw-opacity-0" enter-active-class="tw-transition-all tw-duration-300" leave-active-class="tw-transition-all tw-duration-300" leave-to-class="tw-opacity-0">
            <div class="tw-absolute tw-z-10 tool-tip" v-show="showRuntimeTip" v-if="showCancellationAndRuntimeTips && lotDurationHint">
              <tipp direction="bottom-left" :info="true" :bold="false">
                <template #default>
                  <span v-html="lotDurationHint"></span>
                </template>
              </tipp>
            </div>
          </transition>
          <span class="font-bold">{{ data.sidebarContent.runtimeLabel }} </span><span>{{ lotDuration }}</span>

          <button class="tw-px-1/2 tw-py-1 md:tw-p-0 md:tw-ml-1/2 info-button tw-absolute tw-top-1/2" v-if="showCancellationAndRuntimeTips && lotDurationHint" @click="
              showRuntimeTip ||
                trackClick(
                  `pk_lotterie_konfi_dein-los_icon_bedingungen_laufzeit-${
                    data.lotDuration === LotDuration.OneYear
                      ? '1jahr'
                      : 'unbefristet'
                  }`
                );
              showCancellationPolicyTip = false;
              showRuntimeTip = !showRuntimeTip;
            ">
            <span class="tw-icon tw-icon-information-bold tw-rounded-full tw-border tw-border-solid tw-border-grey-800" :class="{ 'tw-bg-grey-800 tw-text-white': showRuntimeTip }"></span>
          </button>
        </li>

        <li class="summary-list__item">
          <span class="font-bold">{{
            data.sidebarContent.shippingTargetLabel
          }}</span>
          <span v-if="jahreslosData && !data.lotFormat"><a href="#LotFormat">
              {{ data.sidebarContent.shippingTargetPleaseSelectValue }}
            </a>
          </span>
          <span v-else>{{ lotFormatText }}</span>
        </li>
      </ul>

      <div>
        <div class="configurator__summary__sum" ref="pricing">
          <tipp v-if="!data.editModeEnabled && boosterTipp && !disabled" direction="bottom-left" emphasize :bold="false">
            <template #default>
              <span v-html="boosterTipp"></span>
            </template>
          </tipp>
          <div class="tw-relative tw-h-input" v-if="!data.editModeEnabled && boosterData && !disabled" @mousedown.capture="trackLotQuantityClick" @touchstart.capture="trackLotQuantityClick">
            <select v-model="selectedLotCount" id="lotCountSelect" @input="
                lotQuantitySelectIsOpen &&
                  trackClick(
                    `pk_lotterie_5s-ob-konfi_anzahl-lose_auswahl_${$event.target.value}`
                  );
                lotQuantitySelectIsOpen = false;
              " @blur="lotQuantitySelectIsOpen = false" class="select tw-absolute tw-w-full tw-left-0 tw-top-0 tw-h-full tw-opacity-0">
              <option v-for="count in lotCounts" :key="count" :value="count">
                {{ count }}
              </option>
            </select>

            <label for="lotCountSelect" class="tw-relative tw-pointer-events-none tw-block tw-flex tw-items-center tw-h-full tw-border tw-border-grey-800 tw-appearance-none font-bold tw-px-2 tw-pr-4 tw-mt-1 tw-w-full">
              {{ boosterData.lotQuantityDropdownLabel }} {{ selectedLotCount }}

              <span class="tw-icon tw-icon-weiter-down-bold tw-absolute tw-top-1/2 tw-transform tw--translate-y-1/2 tw-right-0 tw-mr-1 tw-text-l"></span>
            </label>
          </div>

          <div>
            <p v-if="selectedLevels.length > 0" class="tw-flex tw-justify-between tw-mt-3 tw-items-end font-bold">
              <span class="configurator__summary__sum__monthly-payment__label tw-text-m">
                {{
                  data.lotDuration === LotDuration.Unlimited
                    ? data.sidebarContent.priceLabelRecurringValue
                    : data.sidebarContent.priceLabelOneTimeValue
                }}
              </span>
              <span class="configurator__summary__sum__price-container tw-flex tw-items-baseline tw-text-l tw-flex-grow">
                <span class="configurator__summary__sum__monthly-payment__value">
                  {{ sumPrice }}
                </span>
                <span class="">{{ "\xa0" }}<abbr title="Euro">€</abbr></span>
                <span class="">{{ "\xa0" }}*</span>
              </span>
            </p>
            <div v-else class="tw-mb-3">
              <strong>{{ data.sidebarContent.noCategorySelectedLabel }}</strong>
            </div>

            <div class="tw--mx-4 tw-mb-3" v-if="getSmallBanner()">
              <div class="tw-w-full tw-py-1 tw-text-white tw-flex tw-justify-center tw-bg-red-500">
                <p v-html="getSmallBanner()"></p>
              </div>
            </div>

            <div class="tw-mb-3 tw-ml-0" v-if="monthlyDrawings > 0">Für {{ monthlyDrawings }} Ziehungen</div>
            <div class="tw--mx-4 tw-mb-3" v-if="data.countdown && data.countdown.showInSidebar">
              <Countdown :date="data.countdown.date" :pluralDayText="data.countdown.dayText" :slideText="data.countdown.sidebarBannerText" :postfixText="data.countdown.trailingText" :backgroundColor="data.countdown.color"></Countdown>
            </div>
          </div>

        </div>
        <template v-if="data.editModeEnabled">
          {{ /* Edit Mode */ }}
          <btn :disabled="disabled" class="tw-mr-0 tw-mb-1" @click.prevent="finishEdit" data-qa="Checkout_Configurator_Button_ProceedToShoppingCart">
            {{ data.sidebarContent.editModeProceedLabel }}
          </btn>

          <btn :disabled="disabled" secondary class="tw-mr-0 tw-mb-1" @click.prevent="cancelEdit" data-qa="Checkout_Configurator_Button_Cancel">
            {{ data.sidebarContent.editModeCancelLabel }}
          </btn>
        </template>

        <template v-else-if="changeLotData">
          <btn :disabled="disabled" class="tw-mr-0 tw-mb-1 tw-text-xs" @click.prevent="proceedToLotChange()" data-qa="WizardSubmitButton">
            {{ changeLotData.proceedButtonLabel }}
          </btn>

          <btn :disabled="disabled" :href="changeLotData.backButtonUrl" secondary button-tag="a" class="tw-mr-0 tw-mb-1" data-qa="WizardBackLink">
            {{ changeLotData.backButtonLabel }}
          </btn>
        </template>

        <template v-else>
          <template v-if="boosterData">
            <btn :disabled="disabled" class="tw-mr-0 tw-mb-1" data-qa="Checkout_Configurator_Button_ProceedToShoppingCart" @click.prevent="
                trackBoosterMultiplier(
                  'pk_click_add_cart_konfi',
                  selectedLotCount
                );
                proceedToShoppingCart();
              ">
              {{ data.sidebarContent.proceedToShoppingLabel }}
            </btn>

            <btn :disabled="disabled" secondary class="tw-mr-0 tw-mb-1" @click.prevent="
                trackBoosterMultiplier(
                  'pk_click_proceed_checkout_konfi',
                  selectedLotCount
                );
                proceedToCheckout();
              " data-qa="Checkout_Configurator_Button_ProceedToCheckout">
              {{ data.sidebarContent.proceedToCheckoutCartLabel }}
            </btn>
          </template>
          <template v-else>
            {{ /* Default */ }}
            <btn :disabled="disabled" class="tw-mr-0 tw-mb-1" @click.prevent="proceedToCheckout" data-qa="Checkout_Configurator_Button_ProceedToCheckout">
              {{ data.sidebarContent.proceedToCheckoutCartLabel }}
            </btn>

            <btn :disabled="disabled" secondary class="tw-mr-0 tw-mb-1" data-qa="Checkout_Configurator_Button_ProceedToShoppingCart" @click.prevent="
                trackClick('pk_click_add_cart_konfi');
                proceedToShoppingCart();
              ">
              {{ data.sidebarContent.proceedToShoppingLabel }}
            </btn>
            <btn
              :disabled="disabled"
              v-if="!boosterData"
              secondary
              class="tw-w-full"
              @click.prevent="proceedToCarddesign"
              data-qa="Checkout_Configurator_Button_ProceedToCardDesigner"
            >
              {{ data.sidebarContent.proceedToCardDesignLabel }}
            </btn>
          </template>
        </template>
      </div>
    </div>
  </section>
</template>

<script>
import { cancelEdit, finishEdit } from "../services/edit-mode-service";
import {
  proceedToCarddesign,
  proceedToCheckout,
  proceedToLotChange,
  proceedToShoppingcart,
} from "../services/proceed-service";
import { LotDuration } from "@/constants";
import Countdown from "./Countdown.vue";
import { showCancellationAndRuntimeTips } from "@/main";
import { getLotDates } from "../services/lotnumber-service";

export default {
  name: "Summary",
  components: {
    Countdown,
  },
  props: {
    drawing: Array,
    playingDate: String,
    shippingDate: String,
    lotFormat: String,
    loading: Boolean,
  },
  data() {
    return {
      lotQuantitySelectIsOpen: false,
      summaryInViewport: false,
      selectedLotCount: 1,
      showCancellationPolicyTip: false,
      showRuntimeTip: false,
      showCancellationAndRuntimeTips,
    };
  },
  mounted() {
    getLotDates(this.data.product).then((lotDates) => {
      if (lotDates.data) {
        this.data.shippingDate = new Date(lotDates.data.ShippingDate);
        this.data.playingDate = new Date(lotDates.data.PlayingDate);
      }
    });

    this.intersectionObserver = new IntersectionObserver((entries) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          this.summaryInViewport = true;
        } else {
          this.summaryInViewport = false;
        }
      });
    });

    this.intersectionObserver.observe(this.$refs.pricing);
  },
  computed: {
    disabled() {
      return !this.drawingWinningCategory.find(
        (drawing) =>
          drawing.name !== "Zusatzspiel" && this.hasSelectedLevel(drawing)
      );
    },
    selectedLevels() {
      return this.drawingWinningCategory.reduce((selectedLevels, drawing) => {
        const selectedLevel = drawing.levels.find((level) => level.isSelected);
        if (selectedLevel) {
          selectedLevels.push(selectedLevel);
        }
        return selectedLevels;
      }, []);
    },
    monthlyDrawings() {
      if (this.data.product !== 'JL') {
        return this.drawingWinningCategory
          .filter((drawing) => this.hasSelectedLevel(drawing))
          .reduce((count, drawing) => count + drawing.drawingsPerMonth, 0);
      } else {
        return "12";
      }
    },
    sumPrice() {
      let price = this.selectedLevels.reduce(
        (price, level) => price + level.price,
        0
      );

      price *= this.selectedLotCount;

      return this.$formatPrice(price);
    },
    showSummaryHint() {
      return ["xs", "sm"].includes(this.$bp.name) && !this.summaryInViewport;
    },
    drawingWinningCategory() {
      return this.drawing.filter(
        (drawing) => drawing.type == "WinningCategory"
      );
    },
    lotFormatText() {
      if (this.lotFormat === "Pdf") {
        return this.data.sidebarContent.shippingTargetPdfValue;
      } else {
        return this.data.sidebarContent.shippingTargetPrintedValue;
      }
    },
    boosterTipp() {
      if (!this.boosterData || !this.boosterData.htmlLotQuantityTipText) {
        return;
      }

      if (this.selectedLotCount > 1) {
        return;
      }

      return this.$interpolate(this.boosterData.htmlLotQuantityTipText, {
        number: this.monthlyDrawings * (this.selectedLotCount + 1),
      });
    },
    lotCounts() {
      return (
        this.boosterData &&
        Array.from({ length: this.boosterData.lotQuantity }, (_, i) => i + 1)
      );
    },
    checkoutConfiguration() {
      const configuration = this.getConfiguration();
      configuration.lotQuantity = this.selectedLotCount;
      return configuration;
    },
    lotDuration() {
      if (this.jahreslosData && this.data.lotDuration === LotDuration.OneYear) {
        return this.data.sidebarContent.runtimeLimitedValue;
      }

      return this.data.sidebarContent.runtimeUnlimitedValue;
    },
    lotDurationHint() {
      if (this.jahreslosData && this.data.lotDuration === LotDuration.OneYear) {
        return this.data.sidebarContent.runtimeLimitedHint;
      }

      return this.data.sidebarContent.runtimeUnlimitedHint;
    },
  },
  methods: {
    getSmallBanner() {
      return this.$options.filters.capitalize(this.data.svlData?.Texts?.SmallBanner || '');
    },
    getSelectedLevelWinningAmount(drawing) {
      let selectedLevel = drawing.levels.find((level) => level.isSelected);
      if (
        selectedLevel.oneTimeWinningSum === 0 ||
        selectedLevel.oneTimeWinningSum === null
      ) {
        return this.$formatPrice(selectedLevel.monthlyWinningSum, false);
      }
      return this.$formatPrice(selectedLevel.oneTimeWinningSum, false);
    },
    hasSelectedLevel(drawing) {
      return !!drawing.levels.find((level) => level.isSelected);
    },
    finishEdit() {
      this.$emit("validate-form", async (ok) => {
        if (!ok) {
          return;
        }
        this.$emit("loading");
        const result = await finishEdit(this.checkoutConfiguration);

        if (result.success) {
          window.location.href = result.data.redirectUrl;
          return;
        } else {
          this.$emit("error", result);
        }

        this.$emit("done");
      });
    },
    async cancelEdit() {
      this.$emit("loading");
      const result = await cancelEdit();

      if (result.success) {
        window.location.href = result.data.redirectUrl;
        return;
      } else {
        this.$emit("error", result);
      }
      this.$emit("done");
    },
    proceedToCheckout() {
      this.$emit("validate-form", async (ok) => {
        if (!ok) {
          return;
        }
        this.$emit("loading");
        const result = await proceedToCheckout(this.checkoutConfiguration);

        if (result.success) {
          window.location.href = result.data.redirectUrl;
          return;
        } else {
          this.$emit("error", result);
        }

        this.$emit("done");
      });
    },
    hideAllTips() {
      this.showCancellationPolicyTip = false;
      this.showRuntimeTip = false;
    },
    async proceedToShoppingCart() {
      this.$emit("validate-form", async (ok) => {
        if (!ok) {
          return;
        }
        this.$emit("loading");
        const result = await proceedToShoppingcart(this.checkoutConfiguration);

        if (result.success) {
          window.location.href = result.data.redirectUrl;
          return;
        } else {
          this.$emit("error", result);
        }

        this.$emit("done");
      });
    },
    async proceedToCarddesign() {
      this.$emit("validate-form", async (ok) => {
        if (!ok) {
          return;
        }

        this.$emit("loading");
        const result = await proceedToCarddesign(this.getConfiguration());

        if (result.success) {
          window.location.href = result.data.redirectUrl;
          return;
        } else {
          this.$emit("error", result);
        }

        this.$emit("done");
      });
    },
    async proceedToLotChange() {
      this.$emit("validate-form", async (ok) => {
        if (!ok) {
          return;
        }
        this.$emit("loading");
        const result = await proceedToLotChange(this.checkoutConfiguration);

        if (result.success) {
          window.location.href = result.data.redirectUrl;
          return;
        } else {
          this.$emit("error", result);
        }

        this.$emit("done");
      });
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
a.option-available {
  @apply tw-text-grey-500;
}

a.option-available:hover {
  @apply tw-underline;
}

.select:focus + label {
  @apply tw-border-2;
}

@screen md {
  .summary {
    max-width: 316px;
  }
}

.summary-list__check.is-checked {
  color: #565656;
}

.check-circle {
  width: 16px;
  height: 16px;
  border: 1px solid #565656;
}

.check-circle.is-checked {
  border-color: #17987d;
  background-color: #17987d;
}

.configurator__summary__sum__price-container {
  &:before {
    content: '';
    display: inline-block;
    width: 100%;
    border-bottom: 1px dotted #ddd;
  }
}

.info-button {
  transform: translateY(-50%);
}

.tool-tip {
  bottom: 22px;
}

select.select {
  display: block;
  visibility: visible;
}
</style>
